/* eslint-disable no-undef, react/no-deprecated */
import React from 'react';
import { render } from 'react-dom';
import ReactDOM from 'react-dom/client';
import Component from './main'; // eslint-disable-line

import PropTypes from 'prop-types';

// Patch React module to support old PropTypes behaviour in muster
React.PropTypes = PropTypes;

if (module.hot) {
  module.hot.accept();
}

const body = document.body;
body.classList.add('morgenfund');
import('./css/morgenfund/index.css');

const root = ReactDOM.createRoot(document.getElementById('app'));
import('./index').then(({ default: Component }) => root.render(<Component />));
